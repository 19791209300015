<template>
  <div class="home">
    <div class="image-container">
      <img
        src="@/assets/BrendaBelen.png"
        alt="Primera imagen"
        class="background-image"
      />
      <img
        src=""
        alt="Segunda imagen"
        class="foreground-image"
        
      />
      <h1 class="title"></h1>
      <h1 class="title2"></h1>
      <h6 class="title3"></h6>
      <img
        class="scroll"
        src="https://www.luxiform.com/wp-content/uploads/2021/01/scroll-down-flecha.gif"
        alt="Scroll"
      />
    </div>
    <transition>
      <FloatingButtons v-show="showButtons" />
    </transition>
    <transition name="bounce">
      <Bio
        v-show="showBio"
        :class="{ bio: showBio, fall: shoBio, nobio: !showBio }"
      />
    </transition>
    <transition name="bounce">
      <TechSkill v-show="showTech" class="tech" />
    </transition>
    <transition name="bounce">
      <Projects v-show="showProject" class="projects" />
    </transition>
 
  </div>
</template>

<script>
import Bio from "@/components/Bio.vue";
import TechSkill from "@/components/TechSkill.vue";
import Projects from "@/components/Projects.vue";
import FloatingButtons from "@/components/FloatingButtons.vue";
export default {
  name: "HomeView",
  components: {
    Bio,
    TechSkill,
    Projects,
    FloatingButtons
},
  data() {
    return {
      showBio: false,
      showTech: false,
      showProject: false,
      showButtons: false,
      isMobile: false,
    };
  },
  methods: {
    handleScroll() {
      const scrollPos = window.scrollY;
      const image = document.querySelector(".background-image");
      const opacity = Math.max(0, 1 - scrollPos / 400);
      const title = document.querySelector(".title");
      const title2 = document.querySelector(".title2");
      const title3 = document.querySelector(".title3");
      const scrll = document.querySelector(".scroll");
      image.style.opacity = opacity;
      image.style.transform = `rotate(${scrollPos / 5}deg)`;
      //para ir al form de contacto------------------------------------------
      if (!this.isMobile && scrollPos >= 3000) {
      this.$router.push({ name: 'ContactView' });
    }
      //animar el bio-------------------------------------------------------
      if (scrollPos >= 450) {
        this.showBio = true;
        this.showButtons=true;
      }else this.showBio = false;
      // desaparecer los boton en caso que este en la landing
      if(scrollPos < 450) this.showButtons = false;
      //techskill------------------------------------------------------------
      if (scrollPos >= 1100){
         this.showTech = true;
          this.showBio=false;
      }else{
         this.showTech = false;
      }
      //projects------------------------------------------------------------
      if (scrollPos >= 1900){
         this.showProject = true;
         this.showTech=false;
      }else{
         this.showProject = false;
      }
      //para el titulo 1 ----------------------------------------------------
      if (scrollPos >= 400) {
        title.style.opacity = "0";
        title2.style.opacity = "0";
        title3.style.opacity = "0";
        scrll.style.opacity = "0";
      }
      else if (scrollPos < 20) {
        title.style.opacity = "1";
        title2.style.opacity = "1";
        title3.style.opacity = "1";
        scrll.style.opacity = "1";
      }
      else {
        title.style.transform = `translate(-50%, ${opacity * -50}%)`;
        title2.style.transform = `translate(-50%, ${opacity * -50}%)`;
        title3.style.transform = `translate(-50%, ${opacity * -50}%)`;
        scrll.style.transform = `translate(-50%, ${opacity * -50}%)`;
      }
      
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Fasthand&display=swap");
.home {
  height: 100vh;
}
.bounce-enter-active {
  animation: bounce-in 2s;
}
.bounce-leave-active {
  animation: bounce-in 2s reverse;
}
@keyframes bounce-in {
  0% {
    transform: scaleX(0);
  }
  80% {
    transform: scaleX(1.1);
  }
  100% {
    transform: scaleX(1);
  }
}
.bio {
  opacity: 1;
  position: absolute;
  z-index: 1;
  width: 60%;
  height: fit-content;
  margin: auto;
  top: 70%;
  left: 20%;
  color: white;
}
.tech {
  opacity: 1;
  position: absolute;
  z-index: 1;
  width: 80%;
  height: fit-content;
  margin: auto;
  top: 150%;
  padding: 8rem;
}
.projects {
  opacity: 1;
  position: absolute;
  z-index: 1;
  width: 90%;
  height: fit-content;
  margin: auto;
  top: 220%;
  left: 5%;
}
.nobio {
  opacity: 0.5;
}
.image-container {
  position: relative;
  height: 100vh;
}
.background-image {
  transition: opacity 0.5s ease-out;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  opacity: 1;
  transform-origin: center center;
  transform: translateZ(0);
  background-size: contain; /* Asegura que la imagen cubra todo el contenedor */
  background-position: center; /* Centra la imagen */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
}

.foreground-image {
  z-index: -1;
  position: absolute;
  top: 100%;
  left: 50%;
  width: 98vw;
  max-width: 98vw;
  height: 1000vh;
  transform: translate(-50%, -50%);
}
.title {
  position: fixed;
  top: 25%;
  left: 60%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  text-shadow: 0px 0px 20px blue;
  transition: transform 0.5s ease-out;
  font-size: 5rem;
  color: azure;
  font-family: "Fasthand", cursive;
}
.title2 {
  position: fixed;
  top: 50%;
  left: 60%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  text-shadow: 0px 0px 20px blue;
  transition: transform 0.5s ease-out;
  font-size: 3rem;
  color: azure;
  font-family: "Fasthand", cursive;
}
.title3 {
  position: fixed;
  top: 5%;
  left: 60%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  transition: transform 0.5s ease-out;
  font-size: 1rem;
  color: azure;
  font-family: Arial, Helvetica, sans-serif;
}
.scroll {
  width: 5%;
  height: 10%;
  position:  relative;
  top: 70%;
  left: 50%;
  right: 0;
  transform: translate(-50%, -50%);
  z-index: 9989;
  border-radius: 50%;
  background-color: white;
}

@media screen and (max-width: 768px) {
  .title {
    font-size: 2rem;
  }
  .projects {
  top: 420%;
  left: 1%;
}
.tech{
  padding: 3rem;
}
}
@media screen and (max-width: 480px) {
  .title {
    font-size: 3rem;
    top: 20%;
  }
  .title2 {
    font-size: 2rem;
    top: 50%;
  }
  .title3{
    top: 1%;
  }
  .projects {
  top: 420%;
  left: 1%;
}
}
</style>