<template>
  <div class="container">
    <h1 class="titulo" align="left">Habilidades:</h1>
    <h1 class="titulo">Estas son las tecnologias con las que trabajo</h1>
    <div class="iconos">
      <a href="https://www.php.net/manual/es/intro-whatis.php" target="_blank" rel="noreferrer">
        <img
          src="@/assets/PHP-logo.svg.png"
          alt="PHP"
        />
      </a> 
      <a href="https://filezilla-project.org/" target="_blank" rel="noreferrer">
        <img
          src="@/assets/filezilla-logo-1.png"
          alt="Filezilla"
        />
      </a>
      <a href=" https://insomnia.rest/download" target="_blank" rel="noreferrer">
        <img
          src="@/assets/1561251841927-Insomnia.png"
          alt="insomia"
        />
      </a>
      <a href="https://www.w3schools.com/css/" target="_blank" rel="noreferrer">
        <img
          src="https://raw.githubusercontent.com/devicons/devicon/master/icons/css3/css3-original-wordmark.svg"
          alt="css3"
        />
      </a>
      <a href="https://expressjs.com" target="_blank" rel="noreferrer">
        <img
          src="https://raw.githubusercontent.com/devicons/devicon/master/icons/express/express-original-wordmark.svg"
          alt="express"
        />
      </a>
      <a href="https://www.figma.com/" target="_blank" rel="noreferrer">
        <img
          src="https://www.vectorlogo.zone/logos/figma/figma-icon.svg"
          alt="figma"
        />
      </a>
      <a href="https://git-scm.com/" target="_blank" rel="noreferrer">
        <img
          src="https://www.vectorlogo.zone/logos/git-scm/git-scm-icon.svg"
          alt="git"
        />
      </a>
      <a href="https://www.w3.org/html/" target="_blank" rel="noreferrer">
        <img
          src="https://raw.githubusercontent.com/devicons/devicon/master/icons/html5/html5-original-wordmark.svg"
          alt="html5"
        />
      </a>

      <a
        href="https://developer.mozilla.org/en-US/docs/Web/JavaScript"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="https://raw.githubusercontent.com/devicons/devicon/master/icons/javascript/javascript-original.svg"
          alt="javascript"
        />
      </a>
    
      <a href="https://www.mysql.com/" target="_blank" rel="noreferrer">
        <img
          src="https://raw.githubusercontent.com/devicons/devicon/master/icons/mysql/mysql-original-wordmark.svg"
          alt="mysql"
        />
      </a>
      <a href="https://nextjs.org/" target="_blank" rel="noreferrer">
        <img
          src="https://cdn.worldvectorlogo.com/logos/nextjs-2.svg"
          alt="nextjs"
        />
      </a>
      <a href="https://nodejs.org" target="_blank" rel="noreferrer">
        <img
          src="https://raw.githubusercontent.com/devicons/devicon/master/icons/nodejs/nodejs-original-wordmark.svg"
          alt="nodejs"
        />
      </a>
      <a href="https://www.postgresql.org" target="_blank" rel="noreferrer">
        <img
          src="https://raw.githubusercontent.com/devicons/devicon/master/icons/postgresql/postgresql-original-wordmark.svg"
          alt="postgresql"
        />
      </a>
      <a href="https://postman.com" target="_blank" rel="noreferrer">
        <img
          src="https://www.vectorlogo.zone/logos/getpostman/getpostman-icon.svg"
          alt="postman"
        />
      </a>
      <a href="https://reactjs.org/" target="_blank" rel="noreferrer">
        <img
          src="https://raw.githubusercontent.com/devicons/devicon/master/icons/react/react-original-wordmark.svg"
          alt="react"
        />
      </a>
      <a href="https://reactnative.dev/" target="_blank" rel="noreferrer">
        <img
          src="https://reactnative.dev/img/header_logo.svg"
          alt="reactnative"
        />
      </a>
      <a href="https://redux.js.org" target="_blank" rel="noreferrer">
        <img
          src="https://raw.githubusercontent.com/devicons/devicon/master/icons/redux/redux-original.svg"
          alt="redux"
        />
      </a>
      <a href="https://tailwindcss.com/" target="_blank" rel="noreferrer">
        <img
          src="https://www.vectorlogo.zone/logos/tailwindcss/tailwindcss-icon.svg"
          alt="tailwind"
        />
      </a>
      <a
        href="https://www.typescriptlang.org/"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="https://raw.githubusercontent.com/devicons/devicon/master/icons/typescript/typescript-original.svg"
          alt="typescript"
        />
      </a>
      <a href="https://vuejs.org/" target="_blank" rel="noreferrer">
        <img
          src="https://raw.githubusercontent.com/devicons/devicon/master/icons/vuejs/vuejs-original-wordmark.svg"
          alt="vuejs"
        />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: "TechSkill",
};
</script>

<style>
.container {
  max-width: 90vw;
  margin: auto;
  padding: 0 3rem;
  object-fit: contain;
}

.titulo {
  text-align: center;
  margin: 50px 0;
  color:azure
}

.iconos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
  margin-bottom: 50px;
}

.iconos a {
  object-fit: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  background-color: #f5f5f5;
  border-radius: 10px;
  transition: transform 0.3s ease;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

.iconos a:hover {
  transform: translateY(-5px);
}

.iconos img {
  max-width: 80%;
  max-height: 80%;
}
</style>
