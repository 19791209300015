<template>
  <div class="floating-buttons">
    <a
      href="https://www.linkedin.com/in/brenda-belen-molina-8772aa211/"
      target="_blank"
    >
      <button class="floating-button linkedin">
        <font-awesome-icon :icon="['fab', 'linkedin-in']" />
      </button>
    </a>
    <a href="https://github.com/bbelen20001" target="_blank">
      <button class="floating-button github">
        <font-awesome-icon :icon="['fab', 'github']" />
      </button>
    </a>
    <a
      href="  "
      target="_blank"
    >
      <button class="floating-button cv">
        CV
        <font-awesome-icon :icon="['fas', 'file-pdf']" />
      </button>
    </a>
    <div v-if="isMobile" class="contact-button">
      <button @click="goToContact">Contacto</button>
    </div>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLinkedinIn, faGithub } from '@fortawesome/free-brands-svg-icons';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';

library.add(faLinkedinIn, faGithub, faFilePdf);

export default {
  name: "FloatingButtons",
  data() {
    return {
      isMobile: false,      
    };
  },
  methods:{
    goToContact() {
      this.$router.push({ name: "ContactView" });
    },
  },
  components: {
    FontAwesomeIcon,
  },
  mounted() {
    const buttons = document.querySelectorAll(".floating-button");
    buttons.forEach((button, index) => {
      button.classList.add("jump-delayed-" + index);
    });
    this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  },
};
</script>

<style>
.floating-buttons {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 9999;
}

.floating-button {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #d411c1;
  color: #fdfdfd;
  border: none;
  margin-bottom: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  animation: jump 1s ease-in-out infinite;
}

.floating-button.jump-delayed-0 {
  animation-delay: 0.4s;
}

.floating-button.jump-delayed-1 {
  animation-delay: 0.7s;
}

.floating-button.jump-delayed-2 {
  animation-delay: 1s;
}

@keyframes jump {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}

.floating-button:hover {
  background-color: #fff;
  color: #1a1a1a;
  box-shadow: 0 0 10px #1a1a1a;
}

.floating-button.linkedin:hover {
  box-shadow: 0 0 10px #0077b5;
}

.floating-button.github:hover {
  box-shadow: 0 0 10px #333;
}

.floating-button.cv:hover {
  box-shadow: 0 0 10px #f44336;
}

.floating-button i {
  font-size: 24px;
  line-height: 60px;
  text-align: center;
}

.floating-button.linkedin i {
  color: #0077b5;
}

.floating-button.github i {
  color: #333;
}

.floating-button.cv i {
  color: #f44336;
}
.contact-button {
display: flex;
justify-content: center;
margin-bottom: 10px;
}

.contact-button button {
display: block;
width: 120px;
height: 40px;
background-color: #1a1a1a;
color: #fff;
border: none;
border-radius: 20px;
font-weight: 500;
font-size: 16px;
cursor: pointer;
transition: all 0.3s ease-in-out;
box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}

.contact-button button:hover {
background-color: #fff;
color: #1a1a1a;
box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3), 0 0 10px #1a1a1a;
}

@media (max-width: 600px) {
.floating-button {
width: 50px;
height: 50px;
}

.floating-button i {
font-size: 20px;
}

.floating-button.cv {
width: 50px;
}

.contact-button button {
width: 100px;
height: 30px;
font-size: 14px;
opacity: 0.5;
}
}
</style>
