<template>
  <div>
    <nav >
    <!-- <router-link to="/">Home </router-link>  -->
    <!-- <router-link to="/tskill">| Tech Skills </router-link>
    <router-link to="/about">| About</router-link> -->
  </nav>
  <router-view/>
  </div>
  
</template>



<style>
body{
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100vw;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 100vw;
}

nav {
  display:none;
  padding: 30px;
  background-color:blue;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
