<template>
  <div>
    <h1 style="color: white;">Mis Proyectos</h1>
    <div class="projects-container">
      <div  
        class="project-card"
        v-for="(project, index) in projects"
        :key="index"
      >
        <img class="image" :src="project.thumbnail" alt="Project thumbnail" />
        <div class="project-details">
          <h3>{{ project.title }}</h3>
          <p>{{ project.description }}</p>
          <div>
            <a :href="project.link" target="_blank" rel="noopener"
              >Ir al proyecto</a
            >
           
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  data() {
    return {
      projects: [
      {
          title: "Doctor Countertop",
          description: "Durante mi tiempo en la empresa, asumí el liderazgo de un proyecto crucial para la gestión de un sistema de software. Este sistema fue diseñado con JavaScript y Tailwind CSS, lo que permitió una implementación eficiente y una interfaz de usuario altamente responsiva. ",
          thumbnail: "https://doctorcountertop.com/assets/img/img2.png",
   
          video:"https://youtu.be/rgFtbGvA51s"
        },
        {
          title: "Ecommerce farmastack ",
          description:
            "Comercio electrónico de artículos farmacéuticos, este proyecto se realizó utilizando React y back Node, la base de datos con postgres y sequelize, los estilos con la biblioteca tailwind y un Image boket con nublado",
          thumbnail: "https://front-farma-stack.vercel.app/assets/logo1-aab4ba09.png",
          link: "https://front-farma-stack.vercel.app/",

        },
        {
          title: " Henry college",
          description:
            "Proyecto de cursos intensivos sobre temas clave, con duraciones de 8 a 20 horas. Aunque no son gratuitos, se ofrecen a precios accesibles para profesionales que buscan continuar su formación.",
          thumbnail: "https://main--chic-queijadas-00551c.netlify.app/assets/LandingGirl-92b78947.jpg",
          link: "https://main--chic-queijadas-00551c.netlify.app/",
        
        },
      
        {
          title: "Rick And Morty in VueJs",
          description: "Muestra Tarjetas de los personajes de la Serie",
          thumbnail: "https://i.imgur.com/Sx49dcn.png",
          link: "https://clever-bombolone-73682b.netlify.app/",
          video:"https://www.loom.com/share/e828ef995f2c484c9a63309c64a4eee1"
        },
      ],
    };
  },
};
</script>

<style>
/* Estilos para pantalla grande */
@media (min-width: 768px) {
  /* Contenedor principal */
  .projects-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 2rem auto;
    max-width: 1200px;
    color:white
  }

  /* Tarjeta de proyecto */
  .project-card {
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    width: calc(33.333% - 1rem);
    transition: all 0.3s ease-in-out;
  }

  .project-card:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }

  /* Imagen del proyecto */
  .image {
    height: 200px;
    object-fit: cover;
    object-position: center;
    width: 100%;
  }

  /* Detalles del proyecto */
  .project-details {
    color:black;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  .project-details h3 {
    color:black;
    margin-top: 0;
    margin-bottom: 0.5rem;
  }

  .project-details p {
    color:black;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  /* Botones */
  .project-details a {
    background-color: #62d2df;
    border-radius: 0.5rem;
    color: #fff;
    display: inline-block;
    font-weight: bold;
    padding: 0.5rem 1rem;
    text-decoration: none;
    text-align: center;
    transition: all 0.3s ease-in-out;
  }

  .project-details a:hover {
    background-color: #1e63b8;
  }

  .project-details .video-button {
    background-color: #e85d04;
    margin-left: 1rem;
  }

  .project-details .video-button:hover {
    background-color: #c84b00;
  }
}

/* Estilos para pantalla pequeña */
@media (max-width: 767px) {
  /* Contenedor principal */
  .projects-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin: 2rem auto;
    max-width: 100%;
    color:white;
  }
  
  /* Tarjeta de proyecto */
  .project-card {
    width: 100%;
  }
  
  /* Imagen del proyecto */
  .image {
    height: 150px;
  }
}
</style>
