<template>
  <div class="bios">
    <div class="foto-container">

    </div>
    <div class="data">
      <h1 style="font-size: 1.5em;">Un poco de mí...</h1>
      <p>
  Soy una apasionada de la tecnología venezolana que comenzó su trayectoria profesional de manera autodidacta, especializándome en el desarrollo de aplicaciones con PHP. Mi dedicación me llevó a completar un bootcamp intensivo, transformándome en <span style="color:#d411c1;">Desarrolladora Full Stack</span> y ampliando mis habilidades en tecnologías emergentes.
</p>
      <p>
        Aunque disfruto de todos los aspectos del desarrollo, mi verdadera pasión es el frontend, donde diseño interfaces de usuario atractivas y funcionales.
      </p>
      <p>
      Ademàs mi experiencia como asistente administrativo también ha mejorado mis habilidades organizativas y de optimización de procesos, lo que refuerza mi compromiso con el campo tecnológico y mi desarrollo profesional continuo.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Bio",
};
</script>

<style>
.bios {
  margin: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  
}

.content {
  object-fit: contain;
  position: relative;
  z-index: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2% 5%;
}

.foto-container {
  width: 35%;
}

.foto1 {

  width: 100%;
  height: 100%; 
  object-fit: contain;
  margin: 0;
  position: fixed; /* Hacer que la imagen sea absoluta dentro de su contenedor */
  top: 0;
  left: 0;
  z-index: -1; /* Para que la imagen de fondo esté detrás del contenido */
}


.data {
  width: 65%;
  color: rgb(255, 255, 255);
  font-size: 30px;
  font-weight: bold;
  text-align: left
  
 
}

@media only screen and (max-width: 768px) {
  .foto-container {
    width: 50%;
  }

  .video {
    display: none;
  }

  .foto1 {
    width: 100vh;
    max-width: 150px;
    margin: 0 auto;
  }

  .data {
    width: 100%;
    font-size: medium;
    text-shadow: none;
  }
}

@media only screen and (max-width: 480px) {
  .foto-container {
    width: 100%;
  }
  .video {
    display: none;
  }

  .foto1 {
    width: 80%;
    max-width: 120px;
    margin: 0 auto;
  }

  .data {
    width: 100%;
    margin-top: 10px;
  }
}
</style>
